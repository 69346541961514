/**
 * @desc 人体图库
 * @author wwj
 * @modify tlzj
 * @modify suyi
 */
// 先改一版 不负责不主动
/**
 * @desc state 参数
 * @param {Array} list 列表数据
 * @param {Boolean} loading 加载状态
 * @param {String} size 图片查看状态  default: normal normal(默认) small(小)  large(大)
 * @param {Array} checkedIds 生成轨迹选中的id
 * @param {Array} this.searchType 查询类型 0: 以图搜图 1： 上传图片进行搜图 2 特征
 * @param {Boolean} this.initTime 初始进入页面时先出现loading,不出现noData组件
 * @param {Object} this.urlParams 地址栏参数
 * @param {String} frameUrl 原始大图  没有用小图代替
 * @param {Array} rects 结构化信息数组
 * @param {String} url 框选Url 左上角小图
 */

/**
 * tips:
 * 1.上传图片搜图--上传图片获取url/base64 ---> 根据url/base64获取特征值 ---> 根据特征值获取列表
 * 2.关联搜图--使用id获取特征值
 */

import React from "react";
import { message } from "antd";
import { toJS } from "mobx";
import Search from "./search";
import { withRouter } from "react-router-dom";

const Wrapper = Loader.loadBusinessComponent("BaseLibComponents", "Wrapper");
const TitleOptions = Loader.loadBusinessComponent("BaseLibComponents", "TitleOptions");
const RightHeader = Loader.loadBusinessComponent("BaseLibComponents", "RightHeader");
const SearchMapModal = Loader.loadBusinessComponent("BaseLibComponents", "SearchMapModal");
const Content = Loader.loadBusinessComponent("ResourceComponents", "Content");
const Loading = Loader.Loading;

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider("body", "tab", "user")
@Decorator.businessObserver({ body: ["searchData"], user: ["userHabit"] })
class BodyLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.initTime = true;
    this.urlParams = Utils.queryFormat(props.location.search);
    this.urlParams.isSearch = this.urlParams.isSearch === "true" ? true : false;
    this.indexDBResult = {};
    this.refContent = React.createRef();
    this.state = {
      list: [],
      checkedIds: [],
      loading: true,
      size: props.user.userHabit.bodyModel || "normal",
      key: Math.random(),
      imgVisible: false, // 框选模态框
      url: "",
      imgId: "", // 以图搜图图片ID
      frameUrl: "", // 模态框Url
      isSearch: false,
      isAidSearch: false,
      rects: [] //结构化信息
    };
  }

  componentDidMount() {
    this.suffix = this.props.location.pathname.split("/")[1];
    this.initUrlOptions();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const bodyModel = nextProps.user.userHabit.bodyModel;
    if (bodyModel && bodyModel !== prevState.size && nextProps.storeId === BaseStore.tab.currentId) {
      return { size: nextProps.user.userHabit.bodyModel };
    }
    return null;
  }
  /**
   * @desc 判断是否是上传图片搜图
   */
  getPictureBySearch = () => {
    return parseInt(this.urlParams.searchType, 10) === 1;
  };

  /**
   * @desc 进行图片结构化
   */
  getFearture = url => {
    let isBase64 = false;
    if (url && url.indexOf("data:image/png;base64") >= 0) {
      url = url.split(",")[1];
      isBase64 = true;
    }
    return Service.body.getFeature({
      [isBase64 ? "base64" : "url"]: url
    });
  };

  /**
   * @desc 根据url获取查询条件
   */
  initUrlOptions = async () => {
    const { searchData } = this.props.body;
    if (!this.urlParams.id) {
      return this.initPageData();
    }

    const result = await LM_DB.get("parameter", this.urlParams.id);
    if (!result) {
      return this.initPageData();
    }
    this.indexDBResult = result;
    const options = {
      url: result.url,
      frameUrl: result.frameUrl,
      isSearch: true
    };

    let featureList = [];
    try {
      featureList = await this.getParmsFeature(result);
      console.log(featureList)
    } catch (e) {
      this.setState({ loading: false });
      return message.warn("提取特征值失败！");
    }

    // TODO 特征值不存在
    if (!Array.isArray(featureList) || featureList.length === 0) {
      return this.mergeSearchData({
        ...Utils.getTimerArea(searchData.timerTabsActive),
        ...result.searchData,
        imgId: result.imgId,
        id: result.smId
      });
    }

    options.rects = featureList;
    //TODO 单个特征时处理逻辑
    if (featureList.length === 1) {
      const mergeParams = {
        ...Utils.getTimerArea(searchData.timerTabsActive),
        ...result.searchData,
        feature: featureList[0].feature,
        imgId: result.imgId,
        id: result.smId
      };
      this.setState({ ...options });
      return this.mergeSearchData(mergeParams, true);
    }
    this.mergeSearchData({ ...Utils.getTimerArea(searchData.timerTabsActive), ...result.searchData, imgId: result.imgId }, false);
    if (result.defaultRect && result.defaultRect.feature) {
      this.onClickDefaultRect(result.defaultRect, result.url);
    } else {
      options.imgVisible = true;
    }

    this.setState({ ...options });
  };

  getParmsFeature = async (parms = {}) => {
    if (parms.rects) {
      const bodyRects = parms.rects.filter(v => v.type === "body");
      if(bodyRects[0].default && !bodyRects[0].feature) {
        // 临时兼容告警有结构化框但无特征值的情况 
        const result = await this.getFearture(parms.url);
        const list = (result.data && result.data.list) || [];
        return list.map(v => ({
          feature: v.feature,
          type: "body",
          value: `${v.rect.left},${v.rect.top},${v.rect.width},${v.rect.height}`
        }));
      } else {
        return bodyRects
      }
    }
    if (parms.feature) {
      return [
        {
          feature: parms.feature,
          type: "body",
          value: parms.data.bodyRect
        }
      ];
    }

    if (parms.smId) {
      const result = await Service.body.bodies({ id: parms.smId });
      return result.data
        ? [
            {
              feature: result.data.bodyFeature,
              type: "body",
              value: result.data.bodyRect
            }
          ]
        : [];
    }

    if (parms.url) {
      const result = await this.getFearture(parms.url);
      const list = (result.data && result.data.list) || [];
      return list.map(v => ({
        feature: v.feature,
        type: "body",
        value: `${v.rect.left},${v.rect.top},${v.rect.width},${v.rect.height}`
      }));
    }
  };

  /**
   * @desc 以图搜图查询判断-上传图片搜图和关联搜图处理
   */
  handleOptionsByType = options => {
    const { searchData } = this.props.body;
    const searchType = this.urlParams.searchType;
    options.offset = searchData.offset;
    options.feature = searchData.feature;
    if (searchType === "0") {
      options.id = this.urlParams.id;
    }
    // 以图搜图增加相似度
    options.score = searchData.score;
    return options;
  };

  /**
   * @desc 查询
   */
  search = async (isLoadMore = false) => {
    const { searchData } = this.props.body;
    let options = Utils.bodyOptions(searchData);
    /**列表查询 */
    !isLoadMore && this.setState({ loading: true });

    const action = this.urlParams.isSearch ? "queryBodiesByFeature" : "queryBodies";
    try {
      const results = await Service.body[action](options, {
        url: this.state.url,
        searchType: this.getPictureBySearch() ? "上传" : "图库"
      });
      if (results && results.data) {
        return this.handleResult(results.data.list || [], isLoadMore);
      }
    } catch (err) {
      console.error(err);
      this.initTime = false;
      this.setState({
        loading: false
      });
      message.warning(err.data.message);
    }
  };

  /**
   * @desc 查询列表成功之后的回调
   * @param {Object} results 请求到的数据
   * @param {Boolean} isLoadMore 加载下一页
   */
  handleResult = (dataList, isLoadMore) => {
    this.initTime = false;
    let list = [];
    if (isLoadMore) {
      list = this.state.list;
    }
    list = list.concat(dataList);
    this.setState({
      list,
      loading: false,
      checkedIds: !isLoadMore ? [] : this.state.checkedIds
    });
    return dataList.length;
  };

  /**
   * @desc 修改查询条件
   * @param {Object} options 查询条件
   * @param {Boolean} needSearch 是否进行查询, 默认查询
   */
  mergeSearchData = async (options, needSearch = true, needReplace = false) => {
    const { frameUrl } = this.state;
    const params = Object.assign({}, options, needSearch ? { minId: null, offset: 0 } : {});
    await this.props.body.mergeSearchData(params);
    if (needReplace) {
      // 对应三种情况--1.普通列表 2.以图搜图 3.上传图片
      const id = !options.id ? Utils.uuid() : options.id;
      const { searchData, ...params } = this.indexDBResult;
      const newSearchData = Object.assign({}, searchData, toJS(this.props.body.searchData));
      const pageData = Object.assign({}, this.urlParams, { id }); // 跳转url参数
      const indexDBObj = Object.assign({}, params, { searchData: newSearchData, id, frameUrl, rects: newSearchData.reacts });
      this.changeIndexDBData(indexDBObj, pageData);
      this.forceUpdatePositon()
      this.forceUpdateStatus()
    }
    needSearch && this.search();
  };

  /**
   * @desc 缓存数据，更新url
   * @parma {object} indexDBObj 存入indexDB的数据
   * @parma {object} urlParams url参数修改
   */
  changeIndexDBData = async indexDBObj => {
    const { tab } = this.props;
    this.indexDBResult = indexDBObj;
    const id = Utils.uuid();
    indexDBObj.id = id;
    await LM_DB.add("parameter", indexDBObj);
    const query = { id };
    if (this.urlParams.isSearch) {
      query.isSearch = true;
    }
    tab.goPage({
      moduleName: "bodyLibrary",
      isUpdate: true,
      data: query,
      action: "replace"
    });
  };

  /**
   * @desc 刷新
   */
  Refresh = () => {
    this.forceUpdateStatus()
    this.forceUpdatePositon()
    const { searchData } = this.props.body;
    const options = searchData.timerTabsActive == 2 ? {} : Utils.getTimerArea(searchData.timerTabsActive);
    this.mergeSearchData({ minId: null, offset: 0, ...options });
  };

  /**
   * @desc 重置
   */
  reset = () => {
    this.forceUpdateStatus()
    this.forceUpdatePositon()
    this.state.url &&
      this.setState({
        url: "",
        isSearch: false,
        isAidSearch: false
      });
    this.indexDBResult = {};
    this.urlParams = {};
    this.initPageData();
  };

  /**
   * @desc 勾选
   */
  onChecked = checkedIds => {
    this.setState({ checkedIds }, () => this.forceUpdateList());
  };
  forceUpdateStatus(){
    try{
      this.refContent.current.setState({hasLoadMore:true})
    }catch(e){}
  }
  forceUpdateList() {
    try {
      setTimeout(() => this.refContent.current.infiniteRef.current.forceUpdateGrid(), 10);
    } catch (e) {}
  }
  forceUpdatePositon(top = 0) {
    try {
      setTimeout(() => this.refContent.current.infiniteRef.current.scrollToPosition(top), 10);
    } catch (e) {}
  }

  /**
   * @desc 滚动加载
   */
  loadMore = () => {
    const { list } = this.state;
    let { offset, limit } = this.props.body.searchData;
    let searchDataObj = this.urlParams.isSearch
      ? {
          offset: offset + limit // 以图搜图
        }
      : {
          minId: list[list.length - 1].id // 图库列表
        };
    return this.mergeSearchData(searchDataObj, false).then(() => this.search(true));
  };

  /**
   * @desc 切换图片显示大小
   */
  changesize = size => {
    const { user } = this.props;
    this.setState({ size});
    user.setUserHabit({ bodyModel: size });
  };

  /**
   * @desc 截图搜图情况下查询特征值后的回调（查询成功和失败都要重置参数，避免特征值查询失败后再修改条件查出数据）
   * @param {string} feature 查询到的特征值
   * @param {String} url 截图后的base64
   * @param {Boolean} needSearch 是否需要更新列表
   */
  changeUrlHandel = (feature, url, needSearch = true) => {
    this.props.body
      .mergeSearchData({
        feature: feature || "",
        imgId: undefined,
        id: undefined
      })
      .then(() => {
        const id = this.urlParams.searchType === "0" ? Utils.uuid() : this.urlParams.id;
        const urlParamsObj = Object.assign({}, this.urlParams, { id, searchType: 1 });
        this.urlParams = urlParamsObj;
        needSearch && this.search();
        const indexDBObj = {
          id,
          searchData: toJS(this.props.body.searchData),
          url
        };
        this.changeIndexDBData(indexDBObj, urlParamsObj);
      });
  };

  /**
   * @desc 重置所有查询条件 - 重置url
   */
  initPageData = () => {
    const { body } = this.props;
    this.urlParams = {};
    body.initData();
    body.mergeSearchData(Utils.getTimerArea(body.searchData.timerTabsActive)).then(() => {
      this.search();
      this.props.tab.goPage({
        moduleName: "bodyLibrary",
        location: this.props.location,
        isUpdate: true,
        data: {},
        action: "replace"
      });
    });
  };

  /**
   * @desc 以图搜图多个特征值点击框选搜图
   */
  handFrame = (url, rects, type, option, imageUrl) => {
    const id = Utils.uuid();
    let indexDBObj = Object.assign({}, this.indexDBResult, { searchData: toJS(this.props.body.searchData), url, id });
    let pageData = Object.assign({}, this.urlParams, { id, isSearch: true, searchType: 1 }); // 跳转url参数
    this.changeIndexDBData(indexDBObj, pageData);
    this.setState({
      url: url,
      frameUrl: imageUrl,
      rects: rects,
      imgVisible: type,
      modalkey: Math.random(),
      isSearch: true
    });
    if (option) {
      this.onClickDefaultRect({ ...option, imgId: undefined }, url);
    }
  };

  /**
   * @desc 以图搜图多个特征值点击默认框搜图
   */
  onClickDefaultRect = (parms = {}, url, otherOptions = {}) => {
    const { searchData } = this.props.body;
    const { frameUrl } = this.state;
    this.urlParams.isSearch = true;
    const option = Object.assign({}, searchData, {
      feature: parms.feature,
      id: parms.smId,
      ...otherOptions
    });
    this.mergeSearchData(option, true);
    this.setState({
      url,
      imgVisible: false,
      frameUrl,
      loading: true
    });
  };

  closeModal = () => {
    this.setState({
      imgVisible: false,
      loading: false
    });
  };
  /**
   * @desc 人脸搜图详情跳转
   */
  goPage = id => {
    const { tab, body } = this.props;
    tab.openDetailPage({
      moduleName: "resourceSearchDetail",
      value: id,
      data: { id },
      beforeAction: async () => {
        const searchData = {
          ...Utils.bodyOptions(toJS(body.searchData)),
          timerTabsActive: body.searchData.timerTabsActive
        };
        await LM_DB.add("parameter", { list: this.state.list, searchData, url: this.state.url, id, type: "body" });
      }
    });
  };
  render() {
    const { list, loading, size, url, key, checkedIds, imgVisible, rects = [], frameUrl, isSearch, isAidSearch } = this.state;
    const { searchData } = this.props.body;
    let listDataIds = [];
    list.map(v => {
      if (v.latitude && v.longitude) {
        listDataIds.push(v.id);
      }
      return v;
    });
    return (
      <Loading loading={loading} wrapperClassName="baselib-spining">
        <Wrapper className="body-wrapper" reset={this.reset} title="人体图库">
          <Search
            mergeSearchData={(values, needSearch) => this.mergeSearchData(values, needSearch, true)}
            searchData={searchData}
            url={url}
            key={key}
            isSearch={isSearch}
            isAidSearch={isAidSearch}
            changeUrl={this.reset}
            handFrame={this.handFrame}
            frameUrl={frameUrl}
            rects={rects}
          />
          <Content
            list={list}
            limit={searchData.limit}
            ref={this.refContent}
            onChecked={this.onChecked}
            loadMore={this.loadMore}
            size={size}
            searchData={searchData}
            isSearch={isSearch}
            goPage={this.goPage}
            type="body"
            checkedIds={checkedIds}
            initTime={this.initTime}
          />
          <div className="header-little-pagtion">
            <TitleOptions onChange={this.changesize} value={size} Refresh={this.Refresh} />
            {isSearch && <RightHeader type="body" list={list} suffix={this.suffix} listDataIds={listDataIds} onChecked={this.onChecked} checkedIds={checkedIds} />}
          </div>
        </Wrapper>
        <SearchMapModal
          className="img-cut-modal"
          visible={imgVisible}
          width="960px"
          title="以图搜图"
          url={frameUrl}
          rects={rects}
          type="body"
          otherModalFooter={true}
          onCancel={this.closeModal}
          onClickDefaultRect={this.onClickDefaultRect}
        />
      </Loading>
    );
  }
}

export default BodyLibrary;
