/**
 * @title 查询条件
 * @author wwj
 * @modify tlzj
 */

import React from 'react'

const Timer = Loader.loadBusinessComponent('BaseLibComponents', 'Timer')
const SearchButton = Loader.loadBusinessComponent('BaseLibComponents', 'SearchButton')
const PointSelect = Loader.loadBusinessComponent('BaseLibComponents', 'PointSelect')
const RadioGroup = Loader.loadBusinessComponent('BaseLibComponents', 'RadioGroup')
const CheckGroup = Loader.loadBusinessComponent('BaseLibComponents', 'CheckGroup')
const ClothesColor = Loader.loadBusinessComponent('BaseLibComponents', 'ClothesColor')
const CheckGroupExclude = Loader.loadBusinessComponent('BaseLibComponents', 'CheckGroupExclude')
const { generation, sex, hat, bigDatePlaceType, lowerTexture, upperTexture, goods, eyeGlass, faceMask, head } = Dict.map
const ScoreSlider = Loader.loadBusinessComponent('BaseLibComponents', 'ScoreSlider')

export default class Search extends React.Component {
  constructor(props) {
    super(props)
    // this.sexArray = [].concat(sex,[{
    //   value: 'other',
    //   label: '其他'
    // }])
    this.sexArray = sex
    this.generationArray = [].concat(generation, [
      {
        value: 'other',
        label: '其他'
      }
    ])
  }
  activeTabId = 'tlzj-' + Math.random()
  /**
   * @desc 编辑查询条件
   */
  change = (options = {}, needSearch = true) => {
    this.props.mergeSearchData(options, needSearch)
  }
  /**
   * @desc 切换时间Tabs
   */
  render() {
    const { searchData = {}, url = '', isSearch, handFrame, frameUrl, rects } = this.props
    const faceMaskFilter = faceMask.filter(v => v.value !== '100253')
    return (
      <div className="baselib-search-wrapper">
        <SearchButton
          type="body"
          url={url}
          change={this.change}
          changeUrl={this.props.changeUrl}
          handFrame={handFrame}
          frameUrl={frameUrl}
          rects={rects}
        />
        {isSearch && <ScoreSlider value={searchData.score} change={this.change} />}
        <div className="small-title">图库筛选 :</div>
        <Timer
          value={searchData.timerTabsActive}
          change={this.change}
          onOk={this.change}
          startTime={searchData.startTime}
          endTime={searchData.endTime}
          needSearch={true}
        />
        <PointSelect onChange={this.change} selectList={searchData.cameraIds} />
        <RadioGroup data={this.sexArray} label="性别" iconFont="icon-S_AID_Sex" value={searchData.sex} name="sex" change={this.change} />
        <RadioGroup
          data={this.generationArray}
          label="年龄段"
          iconFont="icon-S_AID_Age"
          value={searchData.generation}
          name="generation"
          change={this.change}
        />
        <ClothesColor
          label="衣服颜色"
          iconFont="icon-S_Photo_Theme"
          valueUpper={searchData.upColor}
          nameUpper="upColor"
          valueLower={searchData.lowerColor}
          nameLower="lowerColor"
          change={this.change}
          activeTabId={this.activeTabId}
        />
        <CheckGroupExclude
          data={head}
          label="头部特征"
          value={searchData.hat}
          iconFont="icon-S_AID_SunGlasses"
          name="hat"
          change={this.change}
          excludes={[
            ['112003', '112006', '112002', '112007'],
            ['112001', '112008'],
            ['112004', '112009']
          ]}
        />
        {/* <RadioGroup
          data={eyeGlass}
          label='眼镜'
          value={searchData.eyeGlass}
          iconFont='icon-S_AID_SunGlasses'
          name='eyeGlass'
          change={this.change}
        />    
        <RadioGroup
          data={faceMaskFilter}
          label='口罩'
          value={searchData.faceMask}
          iconFont='icon-S_AID_SunGlasses'
          name='faceMask'
          change={this.change}
        />        */}
        <RadioGroup
          data={upperTexture}
          label="上身纹理"
          iconFont="icon-S_AID_ClothesSolid"
          value={searchData.upperTexture}
          name="upperTexture"
          change={this.change}
        />
        <RadioGroup
          data={lowerTexture}
          label="下身类别"
          iconFont="icon-L_Person_PantsShorts"
          value={searchData.lowerTexture}
          name="lowerTexture"
          change={this.change}
        />
        <RadioGroup data={goods} label="随身物品" iconFont="icon-S_AID_Bag" value={searchData.goods} name="goods" change={this.change} />
        <CheckGroup
          label="所在区域类型"
          data={bigDatePlaceType}
          value={searchData.placeType}
          iconFont="icon-S_Place_Community"
          name="placeType"
          change={this.change}
        />
      </div>
    )
  }
}
